import React, { useEffect, useState, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF, useFBX, useTexture, useAnimations } from "@react-three/drei";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import createAnimation from "./converter";
import blinkData from "./blendDataBlinkSmile.json";

import * as THREE from "three";

const _ = require("lodash");
let idleClipAction = undefined;

function Avatar({
  backendAPI,
  avatar_url,
  backendData,
  setAudioSource,
  playing,
  paused,
  setPaused,
  queueInterval,
  speakingInterval,
  setSpeakingInterval,
  responseArray,
  startSpeak,
  setStartSpeak,
  waitForAzure,
  setWaitForAzure,
  waitResponse,
  setWaitResponse,
  emotion,
  waitingSpeeches,
  animations,
  setAnimations,
  playingAnimation,
  setPlayingAnimation,
  setDisplayForm,
  skipped,
  setSkipped,
}) {
  const [delayTime, setDelayTime] = useState(2000);
  let gltf = useGLTF(avatar_url);

  // let animation1 = useGLTF('animations/cyndy-avatar-5.glb');
  let { clips: idleClipsMain } = useAnimations(gltf.animations);
  // let { clips: idleClips1 } = useAnimations(animation1.animations);
  let morphTargetDictionary = {};

  let texturesArray = [],
    colorsArray = [],
    textureNamesArray = [],
    colorNamesArray = [];

  for (let i = 0; i < backendData.textures.length; i++) {
    if (backendData.textures[i].texture_image) {
      textureNamesArray.push(backendData.textures[i].object_name);
      texturesArray.push(backendAPI + backendData.textures[i].texture_image);
    } else {
      colorNamesArray.push(backendData.textures[i].object_name);
      colorsArray.push(backendData.textures[i].texture_color);
    }
  }

  const threeTextures = useTexture(texturesArray);

  _.each(threeTextures, (t) => {
    t.encoding = THREE.sRGBEncoding;
    t.flipY = false;
  });

  gltf.scene.traverse((node) => {
    if (
      node.type === "Mesh" ||
      node.type === "LineSegments" ||
      node.type === "SkinnedMesh"
    ) {
      // console.log(node);
      if (node.material.name.includes("Scalp_Transparency")) {
        node.visible = false;
        // node.material._transmission = 0.1;
      }

      if (node.material.name.includes("Hair_Transparency")) {
        // node.visible = false;
        // node.material._transmission = 0.4;
      }

      if (
        (node.name.startsWith("CC_Base_Body") && node.name.endsWith("_10")) ||
        (node.name.startsWith("CC_Base_Body") && node.name.endsWith("_12"))
      ) {
        node.visible = false;
      }

      if (
        (node.name.startsWith("CC_Base_Body") && node.name.endsWith("_9")) ||
        (node.name.startsWith("CC_Base_Body") && node.name.endsWith("_11"))
      ) {
        node.material.roughness = 0.1;
        node.material.reflectivity = 0.3;
      }

      // node.castShadow = true;
      // node.receiveShadow = true;

      if (node.morphTargetDictionary) {
        morphTargetDictionary[node.name] = node.morphTargetDictionary;
      }
    }
  });

  useEffect(() => {
    if (playingAnimation) {
      if (idleClipAction) idleClipAction.stop();
      idleClipAction = mixer.clipAction(idleClipsMain[playingAnimation]);
      idleClipAction.timeScale = 1;
      idleClipAction.play();
    }
  }, [playingAnimation]);

  useEffect(() => {
    // console.log("main", idleClipsMain);
    setAnimations(idleClipsMain);
    // let idleClipAction1 = mixer.clipAction(idleClipsMain[3]);
    // let idleClipAction2 = mixer.clipAction(idleClips1[0]);
    // let idleClipAction3 = mixer.clipAction(idleClips2[2]);
    // let idleClipAction4 = mixer.clipAction(idleClips3[2]);
    // let idleClipAction5 = mixer.clipAction(idleClips4[3]);
    // let idleClipAction6 = mixer.clipAction(idleClips5[6]);
    // idleClipAction.setLoop(THREE.LoopOnce);
    // idleClipAction.reset();
    // idleClipAction1.timeScale = 0.5;
    // idleClipAction.setLoop(THREE.LoopOnce);
    // idleClipAction.clampWhenFinished = true;
    // idleClipAction1.play();

    // idleClips.forEach(idleClip => {
    //   let idleClipAction = mixer.clipAction(idleClip);
    //   idleClipAction.play();
    // });

    gltf.scene.traverse((node) => {
      if (node.type === "Mesh" || node.type === "SkinnedMesh") {
        let styles = [];
        // Find matching Textures
        // console.log(node.name);
        textureNamesArray.forEach((val, index) => {
          if (
            node.name
              .toLowerCase()
              .includes(textureNamesArray[index].toLowerCase())
          ) {
            styles.push(threeTextures[index]);
          }
        });

        // console.log(styles);

        // Find matching colors
        colorNamesArray.forEach((val, index) => {
          if (
            node.name
              .toLowerCase()
              .includes(colorNamesArray[index].toLowerCase())
          )
            styles.push(colorsArray[index]);
        });

        if (styles.length > 0) {
          const texture = styles.sample();
          if (typeof texture === "string") {
            node.material.color.setHex(texture);
          } else {
            node.material = new THREE.MeshStandardMaterial();
            node.material.map = texture;
          }
        }
      }
    });
  }, []);

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {
    if (!startSpeak && !waitForAzure) return;

    if (waitForAzure) {
      setClips([]);
      setWaitForAzure(false);
    }

    if (speakingInterval >= queueInterval) {
      setDisplayForm(true);
      setStartSpeak(false);
      return;
    }
    if (queueInterval <= 0) return;

    if (!responseArray[speakingInterval]) {
      const intervalId = setTimeout(() => {
        setWaitForAzure(true);
        setStartSpeak(!startSpeak);
      }, 1000);
      return () => clearTimeout(intervalId);
    }

    setWaitResponse(false);

    let { blendData, filename } = responseArray[speakingInterval].data;

    // inja timer bezzarim bebinim in tike cheghad zaman mibare
    // ke masale max kardano kamtar konim

    let newClips = [];

    for (const [key, value] of Object.entries(morphTargetDictionary)) {
      newClips.push(
        createAnimation(
          backendData.created_using_blender,
          blendData,
          value,
          key,
          emotion,
        ),
      );
    }

    filename = backendAPI + filename;

    setClips(newClips);
    setAudioSource(filename);
    setSpeakingInterval(speakingInterval + 1);
    if (backendData.contact_form_in_nth_sentence)
      if (speakingInterval === backendData.contact_form_in_nth_sentence)
        setDisplayForm(true);

    setStartSpeak(false);
  }, [startSpeak]);

  useEffect(() => {
    if (skipped) {
      console.log("umad skipped");
      setClips([]);
      setStartSpeak(false);
      setSpeakingInterval(0);
      setSkipped(false);
    }
  }, [skipped]);

  useEffect(() => {
    for (const [key, value] of Object.entries(morphTargetDictionary)) {
      let blink = createAnimation(
        backendData.created_using_blender,
        blinkData,
        value,
        key,
      );
      let blinkAction = mixer.clipAction(blink);
      blinkAction.play();
    }
  }, []);

  useEffect(() => {
    let intervalId;

    const delayedCode = () => {
      const random_number = Math.floor(Math.random() * waitingSpeeches.length);

      const waitData = waitingSpeeches[random_number].data.blendData;
      const filename = `${backendAPI}${waitingSpeeches[random_number].data.filename}`;

      let newClips = [];

      for (const [key, value] of Object.entries(morphTargetDictionary)) {
        newClips.push(
          createAnimation(
            backendData.created_using_blender,
            waitData,
            value,
            key,
            0,
          ),
        );
      }

      setClips(newClips);
      setAudioSource(filename);

      // Use functional update to ensure the correct delayTime value
      setDelayTime((prevDelayTime) => prevDelayTime + 6000);
    };

    if (!waitResponse) {
      // Clear the interval if it exists
      setDelayTime(2000);
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null; // Reset intervalId
      }
      return;
    }

    if (waitingSpeeches.length === 0) return;

    console.log(delayTime);
    intervalId = setInterval(delayedCode, delayTime);

    // Clear the interval when waitResponse becomes false
    return () => {
      clearInterval(intervalId);
      intervalId = null; // Reset intervalId
    };
  }, [waitResponse, delayTime]);

  // Play animation clips when available
  useEffect(() => {
    if (skipped) {
      _.each(clips, (clip) => {
        animations[clip.uuid].stop();
      });
    }
    if (playing === false) {
      if (paused) {
        _.each(clips, (clip) => {
          animations[clip.uuid].paused = true;
        });
      } else {
        // BUG BUG BUG
        // _.each(animations, (animation) => {
        //     animation.stop();
        //   });
        setPaused(null);
      }
    } else {
      if (paused === false) {
        setPaused(false);
        _.each(clips, (clip) => {
          animations[clip.uuid].paused = false;
        });
      } else {
        let total_clips_duration = 0;
        _.each(clips, (clip) => {
          total_clips_duration = clip.duration;
          animations[clip.uuid] = mixer.clipAction(clip);
          animations[clip.uuid].setLoop(THREE.LoopOnce);
          // animations[clip.uuid].startAt(paused);
          animations[clip.uuid].play();
        });

        let finished = true;
        if (idleClipAction) {
          if (!idleClipAction.enabled) {
            finished = !idleClipAction.enabled;
            idleClipAction.stop();
          }
        }
        if (finished) {
          idleClipAction = undefined;
          let talking_ideal_animations = [
            "describe-by-hand-middle",
            "Rubbing-hand-middle",
          ];
          let random = Math.floor(
            Math.random() * talking_ideal_animations.length,
          );
          const the_animation = animations.find(
            (animation) => animation.name === talking_ideal_animations[random],
          );
          if (the_animation) {
            const animation_speed = 1;
            const animation_time = the_animation.duration / animation_speed;
            const number_of_repeats = Math.floor(
              total_clips_duration / animation_time,
            );
            idleClipAction = mixer.clipAction(the_animation);
            idleClipAction.timeScale = animation_speed;
            idleClipAction.setLoop(THREE.LoopRepeat, number_of_repeats);
            // idleClipAction.syncWith(clips);
            idleClipAction.play();
          }
        }
      }
    }
  }, [playing, skipped]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <primitive
      object={gltf.scene}
      position={
        window.location.hostname === "localhost"
          ? [0, -160, 0]
          : JSON.parse(backendData.model_position)
      }
    />
  );
}

export default Avatar;
