import { AnimationClip, NumberKeyframeTrack } from "three";

var fps = 60;

function ccModifiedKey(key) {
  const mapping = {
    browDownLeft: "Brow_Drop_L",
    browDownRight: "Brow_Drop_R",
    browInnerUp: ["Brow_Raise_Inner_L", "Brow_Raise_Inner_R"],
    browOuterUpLeft: "Brow_Raise_Outer_L",
    browOuterUpRight: "Brow_Raise_Outer_R",
    cheekPuff: ["Cheek_Puff_L", "Cheek_Puff_R"],
    cheekSquintLeft: "Cheek_Raise_L",
    cheekSquintRight: "Cheek_Raise_R",
    eyeBlinkLeft: "Eye_Blink_L",
    eyeBlinkRight: "Eye_Blink_R",
    eyeLookDownLeft: "Eye_L_Look_Down",
    eyeLookDownRight: "Eye_R_Look_Down",
    eyeLookInLeft: "Eye_L_Look_L",
    eyeLookInRight: "Eye_R_Look_L",
    eyeLookOutLeft: "Eye_L_Look_R",
    eyeLookOutRight: "Eye_R_Look_R",
    eyeLookUpLeft: "Eye_L_Look_Up",
    eyeLookUpRight: "Eye_R_Look_Up",
    eyeSquintLeft: "Eye_Squint_L",
    eyeSquintRight: "Eye_Squint_R",
    eyeWideLeft: "Eye_Wide_L",
    eyeWideRight: "Eye_Wide_R",
    headRoll: "Head_Backward.001",
    jawForward: "Jaw_Forward", //problem
    jawLeft: "Jaw_L",
    jawOpen: "Merged_Open_Mouth",
    jawRight: "Jaw_R",
    leftEyeRoll: "Eyelash_Upper_Up_L", //problem
    mouthClose: "Mouth_Close",
    mouthDimpleLeft: "Mouth_Dimple_L",
    mouthDimpleRight: "Mouth_Dimple_R",
    mouthFrownLeft: "Mouth_Frown_L",
    mouthFrownRight: "Mouth_Frown_R",
    mouthFunnel: [
      "Mouth_Funnel_Up_L",
      "Mouth_Funnel_Up_R",
      "Mouth_Funnel_Down_L",
      "Mouth_Funnel_Down_R",
    ],
    mouthLeft: "Mouth_L.001",
    mouthLowerDownLeft: "Mouth_Down_Lower_L",
    mouthLowerDownRight: "Mouth_Down_Lower_R",
    mouthPressLeft: "Mouth_Press_L",
    mouthPressRight: "Mouth_Press_R",
    mouthPucker: [
      "Mouth_Pucker_Up_L",
      "Mouth_Pucker_Up_R",
      "Mouth_Pucker_Down_L",
      "Mouth_Pucker_Down_R",
    ],
    mouthRight: "Mouth_R",
    mouthRollLower: ["Mouth_Roll_In_Lower_L", "Mouth_Roll_In_Lower_R"],
    mouthRollUpper: ["Mouth_Roll_In_Upper_R", "Mouth_Roll_In_Upper_L"],
    mouthShrugLower: "Mouth_Shrug_Lower",
    mouthShrugUpper: "Mouth_Shrug_Upper",
    mouthSmileLeft: "Mouth_Smile_L",
    mouthSmileRight: "Mouth_Smile_R",
    mouthStretchLeft: "Mouth_Stretch_L",
    mouthStretchRight: "Mouth_Stretch_R",
    mouthUpperUpLeft: "Mouth_Up_Upper_L",
    mouthUpperUpRight: "Mouth_Up_Upper_R",
    noseSneerLeft: "Nose_Sneer_L",
    noseSneerRight: "Nose_Sneer_R",
    rightEyeRoll: "Eyelash_Upper_Up_R",
    tongueOut: "Tongue_Out",
  };

  // Perform the mapping
  return mapping[key];
}

function createAnimation(
  created_using_blender,
  recordedData,
  morphTargetDictionary,
  bodyPart,
  emotion = 0,
) {
  // console.log(recordedData)
  // console.log(morphTargetDictionary)
  // console.log(bodyPart)

  // console.log("----morphTargetDictionary", morphTargetDictionary)

  if (recordedData.length !== 0) {
    // console.log(recordedData)
    let animation = [];
    for (let i = 0; i < Object.keys(morphTargetDictionary).length; i++) {
      animation.push([]);
    }
    let time = [];
    let finishedFrames = 0;
    recordedData.forEach((d, i) => {
      Object.entries(d.blendshapes).forEach(([key, value]) => {
        const new_key = created_using_blender ? key : ccModifiedKey(key);
        if (!(new_key in morphTargetDictionary)) {
          return;
        }
        // console.log(new_key);
        // console.log(new_key)
        if (key === "jawOpen") {
          value -= 0.05;
        }

        // if (key === 'mouthSmileRight') {
        //   value += 0.1;
        // }

        // if (key === 'mouthClose') {
        //   value /= 5;
        // }
        if (typeof new_key !== "string") {
          for (const a_key of new_key) {
            if (!(a_key in morphTargetDictionary)) {
              return;
            }
            animation[morphTargetDictionary[a_key]].push(value);
          }
        } else {
          if (!(new_key in morphTargetDictionary)) {
            return;
          }
          animation[morphTargetDictionary[new_key]].push(value);
        }

        // if (modifiedKey(key) === 'Mouth_Close') {
        //   value -= 0.4;
        // }
      });
      time.push(finishedFrames / fps);
      finishedFrames++;
    });

    // console.log("-----animation", animation);

    let tracks = [];

    //create morph animation
    Object.entries(recordedData[0].blendshapes).forEach(([key, value]) => {
      const new_key = created_using_blender ? key : ccModifiedKey(key);
      if (!(new_key in morphTargetDictionary)) {
        return;
      }
      let i = morphTargetDictionary[new_key];

      let track = new NumberKeyframeTrack(
        `${bodyPart}.morphTargetInfluences[${i}]`,
        time,
        animation[i],
      );

      tracks.push(track);
    });

    return new AnimationClip("animation", -1, tracks);
  }
  return null;
}

export default createAnimation;
